import React, { useContext, } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import iconimage from "../assets/images/profileImg.png";
import { AppContext } from "../Context/AppContext";

const Dashnavbar = ({ toggleDrawer }) => {
  const navigate = useNavigate();
  const { profileData, isLoading } = useContext(AppContext);
  return (
    <div className="dashnavbar-container v-center">
      <div>
        <Icon
          icon="ion:menu"
          width={24}
          height={24}
          onClick={toggleDrawer}
          className={`menu-icon d-none`}
        />
      </div>
      {/* <div className="nav-search v-center">
        <Icon icon="ic:outline-search" width={24} height={24} color="#fff" />
        <input
          type="search"
          name=""
          id=""
          placeholder="Search"
          className="fw-medium"
        />
      </div> */}
      {isLoading ? (
        <div className="loader1"></div>
      ) : (
        <div
          className="profile-div v-center cursor-pointer"
          onClick={() => {
            navigate("/settings");
          }}
        >
          {profileData?.picture ? (
            <img src={profileData.picture} alt="profile" />
          ) : (
            <img src={iconimage} alt="profile" />
          )}
          <p className="text-medium fw-semibold">{profileData?.username}</p>
        </div>
      )}
    </div>
  );
};

export default Dashnavbar;
