import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

const DeleteModal = ({ isdelModal, setisdelModal, removePersonaCard }) => {
  const handleOk = () => {
    setisdelModal(false);
  };
  const handleCancel = () => {
    setisdelModal(false);
  };
  const handleYesClick = () => {
    removePersonaCard();
    setisdelModal(false);
  };
  return (
    <>
      <Modal
        open={isdelModal}
        onOk={handleOk}
        onCancel={handleCancel}
        className="del-modal"
        centered={true}
        width="38%"
        maskClosable={false}
      >
        <div className="modal-content v-center h-center flex-column">
          <h5 className=" fw-bold fw-medium">
          Are you sure you want to delete this persona?
          </h5>
          <div className="d-flex gap-4">
            <button
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </button>
            <button onClick={handleYesClick}>Delete</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
