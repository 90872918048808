import React, { useContext, useEffect, useState } from "react";
import { Modal } from "antd";
import DropdownInputModal from "./dropdownInputModal";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { AppContext } from "../Context/AppContext";
import FreeTrialEnded from "./freeTrialended";
import { Icon } from "@iconify/react/dist/iconify.js";
const topics = [
  {
    category: "Entrepreneur",
    topics: [
      "Startup Strategy Planning",
      "Business Model Validation",
      "Funding & Investment",
      "Growth Hacking Techniques",
      "Customer Acquisition",
      "Pricing Strategy",
      "Market Entry Planning",
      "Competition Analysis",
    ],
  },
  {
    category: "Educators",
    topics: [
      "Lesson Planning Tips",
      "Student Engagement Strategies",
      "Classroom Management",
      "Digital Teaching Tools",
      "Assessment Methods",
      "Special Education Approaches",
      "Parent Communication",
      "Educational Technology Integration",
    ],
  },
  {
    category: "Healthcare Professionals",
    topics: [
      "Patient Care Best Practices",
      "Healthcare Management",
      "Medical Ethics Discussions",
      "Team Coordination",
      "Healthcare Technology Updates",
      "Patient Communication",
      "Work-Life Balance in Healthcare",
      "Medical Research Analysis",
    ],
  },
  {
    category: "Relationship/Companionship",
    topics: [
      "Dating Advice & Tips",
      "Building Better Relationships",
      "Communication Skills",
      "Conflict Resolution",
      "Long-distance Relationship Strategies",
      "Understanding Love Languages",
      "Setting Healthy Boundaries",
      "Marriage Preparation",
    ],
  },
  {
    category: "Fashion Designers",
    topics: [
      "Trend Analysis & Forecasting",
      "Sustainable Fashion",
      "Collection Development",
      "Fashion Business Strategy",
      "Material Sourcing",
      "Design Portfolio Review",
      "Brand Development",
      "Fashion Marketing",
    ],
  },
  {
    category: "Parents",
    topics: [
      "Child Development Stages",
      "Parenting Techniques",
      "Education Planning",
      "Work-Life Balance",
      "Children's Health",
      "Family Activities",
      "Teen Communication",
      "Child Behavior Management",
    ],
  },
  {
    category: "Job Search",
    topics: [
      "Resume Building",
      "Career Path Planning",
      "Job Search Strategy",
      "Salary Negotiation",
      "LinkedIn Optimization",
      "Industry Transitions",
      "Remote Work Success",
      "Professional Networking",
    ],
  },
  {
    category: "Learning Skills",
    topics: [
      "Study Techniques",
      "Memory Improvement",
      "Time Management",
      "Note-Taking Methods",
      "Learning Style Assessment",
      "Concentration Tips",
      "Exam Preparation",
      "Self-Paced Learning",
    ],
  },
  {
    category: "Language Learning",
    topics: [
      "Language Learning Methods",
      "Conversation Practice",
      "Grammar Mastery",
      "Vocabulary Building",
      "Cultural Understanding",
      "Pronunciation Tips",
      "Language Immersion",
      "Learning Resources",
    ],
  },
  {
    category: "Interviewing Skills",
    topics: [
      "Interview Preparation",
      "Common Questions & Answers",
      "Body Language Tips",
      "Virtual Interview Success",
      "Technical Interview Prep",
      "Behavioral Interview Practice",
      "Follow-up Strategies",
      "Confidence Building",
    ],
  },
  {
    category: "Personal Adviser",
    topics: [
      "Goal Setting & Planning",
      "Personal Finance",
      "Work-Life Balance",
      "Decision Making",
      "Stress Management",
      "Personal Growth",
      "Habit Formation",
      "Life Transitions",
    ],
  },
  {
    category: "Business & Strategy",
    topics: [
      "Product Strategy Review",
      "Market Analysis",
      "Business Plan Feedback",
      "Competitor Research",
      "Brand Positioning",
    ],
  },
  {
    category: "Creative & Brainstorming",
    topics: [
      "Creative Writing Ideas",
      "Content Strategy",
      "Marketing Campaign Brainstorm",
      "Design Feedback",
      "Innovation Workshop",
    ],
  },
  {
    category: "Professional Development",
    topics: [
      "Career Planning",
      "Leadership Skills",
      "Public Speaking Tips",
      "Interview Preparation",
      "Professional Growth",
    ],
  },
  {
    category: "Research & Analysis",
    topics: [
      "Industry Trends Analysis",
      "Data Interpretation",
      "Research Planning",
      "Survey Analysis",
      "Academic Paper Review",
    ],
  },
  {
    category: "Ideas for Youtube Videos",
    topics: [
      "Youtube Video ideas",
      "Superfoods and Their Benefits",
      "Fitness Routines",
      "Travel Planning for Groups",
      "Tourism Marketing Strategies",
      "Event Planning Best Practices",
      "Virtual Event Management",
      "Personal Brand Online Building",
      "Meal Prep Ideas",
      "Plant-Based Diets",
      "Nutrition for Weight Loss",
    ],
  },
];

const ChoosetopicModal = ({ IschooseModalOpen, setIschooseModalOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isGuestModal, setisGuestModal] = useState(false);
  const [isdropdownInputOpen, setisdropdownInputOpen] = useState(false);
  const [topicDetails, setTopicDetails] = useState("");
  const [error, setError] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [showTopics, setShowTopics] = useState(true);
  const charLimit = 400;
  const { setChatType, profileData } = useContext(AppContext);
  const userId = secureLocalStorage.getItem("userId");
  useEffect(() => {
    localStorage.setItem("topic", topicDetails);
  }, [topicDetails]);

  const handleTextareaChange = (e) => {
    const input = e.target.value;

    if (input.length <= charLimit) {
      setTopicDetails(input);
      setCharCount(input.length);
    }

    if (input.trim() !== "") {
      setError("");
    }
  };
  const handleOk = () => {
    setIschooseModalOpen(false);
  };
  const handleCancel = () => {
    setTopicDetails("");
    setError("");
    setIschooseModalOpen(false);
    setShowTopics(true);
  };
  const onNextClick = () => {
    if (topicDetails.trim() === "") {
      setError("Topic details are required.");
      return;
    }
    handleTopicValidation();
  };

  const handleTopicValidation = () => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/topic/topic_checking`, {
        topic: topicDetails,
        userid: userId,
      })
      .then((res) => {
        setChatType("chose_topic");
        setisdropdownInputOpen(true);
        setIschooseModalOpen(false);
      })
      .catch((err) => {
        setError(
          err.response.data.Error ? err.response.data.Error : err.response.data
        );
        // console.log(err.response.data.Error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleSkipClick = () => {
    if (profileData?.username === "Guest") {
      setisGuestModal(true);
    } else {
      setShowTopics(false);
    }
  };
  const filteredTopics = topics
    .map((category) => ({
      ...category,
      topics: category.topics.filter((topic) =>
        topic.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    }))
    .filter((category) => category.topics.length > 0);
  return (
    <>
      <Modal
        open={IschooseModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="uplaodDoc-modal"
        centered={true}
        width="38%"
        maskClosable={false}
      >
        {showTopics ? (
          <>
            <div className="modal-content v-center h-center flex-column topics">
              <h4>Our popular category</h4>
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search topics..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input mt-3"
                />
                <span className="search-icon">
                  <Icon
                    icon="material-symbols:search-rounded"
                    width={25}
                    height={25}
                    color="#FFFFFF"
                    className="cursor-pointer back-icon"
                  />
                </span>
              </div>

              <div className="topiccategory  ">
                {filteredTopics.length > 0 ? (
                  filteredTopics.map((item) => (
                    <div
                      key={item.category}
                      className="cat-wrapper"
                      onClick={() => setShowTopics(false)}
                    >
                      <h2>{item.category}</h2>
                      <ul
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        {item.topics.map((topic) => (
                          <li
                            onClick={() => setTopicDetails(topic)}
                            key={topic}
                          >
                            {topic}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))
                ) : (
                  <div className="no-topics-message">
                    <p>No topics found for your search.</p>
                  </div>
                )}
              </div>
              <button className="new-class" onClick={handleSkipClick}>
                skip to enter your own topic
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="modal-content v-center h-center flex-column">
              <div className="v-center flex-column">
                <h3 className="fw-bold">Add Topic Details for Discussion</h3>

                <p className="text-small  text-small mt-2">
                  Kindly add Topic details below for starting Discussion
                </p>
              </div>
              <div>
                <textarea
                  type="text"
                  placeholder="Explain how AI can assist in solving complex problems and making data-driven decisions."
                  className="modal-input"
                  value={topicDetails}
                  onChange={handleTextareaChange}
                ></textarea>
                {error && (
                  <p className="text-small-larger text-red mt-2">{error}</p>
                )}
              </div>

              <div>
                <button
                  className="dash-pink-btn v-center h-center"
                  onClick={onNextClick}
                >
                  Continue
                </button>
              </div>
            </div>
          </>
        )}
      </Modal>
      <DropdownInputModal
        isdropdownInputOpen={isdropdownInputOpen}
        setisdropdownInputOpen={setisdropdownInputOpen}
        topicDetails={topicDetails}
      ></DropdownInputModal>
      <FreeTrialEnded
        isGuestModal={isGuestModal}
        setisGuestModal={setisGuestModal}
        isClosable={true}
      ></FreeTrialEnded>
    </>
  );
};

export default ChoosetopicModal;
