import React, { useContext } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../Context/AppContext";
import secureLocalStorage from "react-secure-storage";

const FreeTrialEnded = ({ setisGuestModal, isGuestModal , isClosable }) => {
  const navigate = useNavigate();
  const {setTime} = useContext(AppContext)
  const handleSignIn = () => {
    setisGuestModal(false);
    setTime({ minute: 0, second: 0 });        
    secureLocalStorage.removeItem('authToken')
    navigate("/signin");
  };

  const handleSignUp = () => {
    setisGuestModal(false);
    setTime({ minute: 0, second: 0 });        
    secureLocalStorage.removeItem('authToken')
    navigate("/signup");
  };
  const handleClose = () => {
    if (isClosable) {
      setisGuestModal(false);
    }
  };
  return (
    <Modal
      open={isGuestModal}
      footer={null}
      centered={true}
      width={300}
      maskClosable={isClosable}
      closable={isClosable}
      onCancel={handleClose}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "25vh",
        }}
      >
        <p className="fw-medium text-large text-center">
          Please choose an option to continue
        </p>
        <div style={{ display: "flex", gap: "20px" , marginTop: "15px" }}>
          <button
            className="dash-pink-btn"
            onClick={handleSignIn}
            style={{ padding: "10px 20px", fontSize: "1rem" }}
          >
            Sign In
          </button>
          <button
            className="dash-pink-btn"
            onClick={handleSignUp}
            style={{ padding: "10px 20px", fontSize: "1rem" }}
          >
            Sign Up
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FreeTrialEnded;
